
.Dropdown-root .dropdown-control {
  /*flex-grow: 1;*/
  /*min-height: 64px;*/
  /*font-size: 16px;*/
  /*color: #0F1324;*/
  /*padding: 32px 16px 8px 16px;*/
  /*border: 1px solid #8f8fab99;*/
  /*border-radius: 12px;*/
  /*cursor: pointer;*/
  background: hsla(0, 0%, 100%, .2);
  border-radius: 12px;
  border: none;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 34px 12px 16px;
  cursor: pointer;
}

.Dropdown-root .dropdown-placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  text-transform: capitalize;
}

.Dropdown-root .Dropdown-arrow {
  top: 18px;
  border-width: 0 2px 2px 0 !important;
  border-color: #fff !important;
  border-style: solid;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 18px;
}

.Dropdown-root.is-open .Dropdown-arrow {
  top: 22px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Dropdown-root .Dropdown-menu {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 14px;
  margin-top: 2px;
}

.Dropdown-menu-top {
  top: auto !important;
  bottom: 35px !important;
}


.Dropdown-root .Dropdown-option {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin: 4px !important;
  padding: 6px 0 !important;
  text-align: center;
}

.highcharts-credits {
  display: none !important;
}

.Dropdown-root .Dropdown-option.is-selected,
.Dropdown-root .Dropdown-option:hover {
  background-color: rgba(15, 19, 36, 0.08);
  border-radius: 8px;
  color: white;
}

.MenuWrapper {
  position: absolute;
  left: -100%;
  opacity: 0;
}


@media only screen and (max-width: 991px) {
  .MenuWrapper .Dropdown-root .dropdown-control {
    background: rgba(181, 181, 181, 0.22);
    width: 73px;
  }

  .MenuWrapper .Dropdown-root .Dropdown-menu {
    background-color: rgba(204, 204, 204, 0.29);
    width: 73px;
  }

  .MenuWrapper .Dropdown-root .dropdown-placeholder,
  .Dropdown-root .Dropdown-option.is-selected,
  .Dropdown-root .Dropdown-option:hover {
    color: #282359;
  }
  .Dropdown-root .Dropdown-option {
    color: rgba(40, 35, 89, 0.8);
  }
  .Dropdown-root .Dropdown-arrow {
    border-color: #282359 !important;
  }
}

/* dropdown ------------------- */
.Dropdown-root.dropdownAdmin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 1300px) {
  .Dropdown-root.dropdownAdmin {
    width: 492px;
  }
}

@media (max-width: 1299px) {
  .Dropdown-root.dropdownAdmin {
    width: auto;
  }
}

.dropdownAdmin .Dropdown-control {
  position: relative;
  overflow: hidden;
  background-color: white;
  flex-grow: 1;
  min-height: 64px;
  font-size: 16px;
  color: #0F1324;
  padding: 32px 16px 8px 16px;
  border: 1px solid #8f8fab99;
  border-radius: 12px;
  transition: all 200ms ease;
}

.dropdownAdminError.dropdownAdmin .Dropdown-control {
  border-width: 2px;
  border-color: #FF453A;
  background: rgba(255, 69, 58, 0.03);
}

.dropdownAdminError.dropdownAdmin {
  background: rgba(255, 69, 58, 0.03);
}

.dropdownAdmin .Dropdown-control:hover {
  /*box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);*/
  box-shadow: none;
}

.dropdownAdmin .Dropdown-arrow {
  border-color: #0F1324 transparent transparent !important;
  border-color: #0F1324 !important;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  /*margin-top: -ceil(2.5);*/
  padding: 3px;
  position: absolute;
  right: 28px;
  top: 28px;
  width: 0
}

.dropdownAdmin .dropdown-placeholder {
  color: rgb(206, 206, 218);
  font-size: 16px;
  color: #0F1324;
  font-weight: normal;
}

.dropdownAdmin + .dropdownTitleWrapper {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #0F1324;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background: transparent;
  -webkit-transition: all .5s;
  transition: all .5s;
  pointer-events: none;
}

.dropdownAdminError.dropdownAdmin + .dropdownTitleWrapper,
.dropdownAdminError.dropdownAdmin + .dropdownTitleWrapper > * {
  color: #FF453A !important;
}

.dropdownAdmin.is-open + .dropdownTitleWrapper,
.dropdownAdmin.is-open + .dropdownTitleWrapper > * {
  color: #2c4135 !important;
}

.dropdownAdmin .Dropdown-control {
  text-align: left;
  justify-content: flex-start;
}

.dropdownAdmin.is-open .Dropdown-control {
  border-color: #2c4135;
  border-width: 2px;
}

.dropdownAdmin.is-open .Dropdown-arrow {
  border-color: transparent transparent #0F1324;
  border-color: #0F1324 !important;
  border-width: 0 5px 5px;
  top: 30px;
}

.dropdownAdmin .Dropdown-menu {
  background-color: white;
  border: 0;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  margin-top: 2px;
  -webkit-overflow-scrolling: touch;
}

.dropdownAdmin .Dropdown-menu .Dropdown-group > .Dropdown-title {
  padding: 8px 10px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  text-transform: capitalize;
}

.dropdownAdmin .Dropdown-option {
  box-sizing: border-box;
  color: #0F1324;
  cursor: pointer;
  display: block;
  margin: 8px !important;
  padding: 8px 8px 8px 16px !important;
  text-align: left !important;
  font-weight: normal;

}

.dropdownAdmin .Dropdown-option:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.dropdownAdmin .Dropdown-option:hover {
  /*background-color: #8f8fab14;*/
  color: #2c4135;
}

.dropdownAdmin .Dropdown-option.is-selected {
  background-color: #8f8fab14;
  color: #2c4135;
}

[id*="multiselectContainerReact"] .optionListContainer .optionContainer .option.highlight, [id*="multiselectContainerReact"] .optionListContainer .optionContainer .option:hover, [id*="multiselectContainerReact"] .optionListContainer .optionContainer .option:active,
[id*="multiselectContainerReact"] .optionListContainer .optionContainer .option:focus {
  background-color: #8f8fab14;
  color: #2c4135 !important;
}

.dropdownAdmin .optionListContainer {
  position: absolute;
  //top: 65px;
  z-index: 99;
  margin-top: 0;
  word-break: break-word;
  bottom: 0;
  transform: translateY(100%);
  border-radius: 15px;
}

.customDropdownForAdminPagemanagement .optionListContainer {
  position: absolute;
  z-index: 99;
  margin-top: 0;
  word-break: break-all;
  bottom: 100%;
  border-radius: 15px;
  transform: none;
}

.dropdownAdmin .Dropdown-noresults {
  box-sizing: border-box;
  color: #ccc;
  cursor: default;
  display: block;
  padding: 8px 10px;
}

.newsFilterSelect .newsFilterSelect-dropdown-control,
.adminTableFilterSelectSort .adminTableFilterSelect-dropdown-control,
.adminTableFilterSelect .adminTableFilterSelect-dropdown-control {
  border: none;
  background-color: transparent;
  padding: 0 15px 0 0;
  color: #0F1324;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.newsFilterSelect .newsFilterSelect-dropdown-control:hover,
.adminTableFilterSelectSort .adminTableFilterSelect-dropdown-control:hover,
.adminTableFilterSelect .adminTableFilterSelect-dropdown-control:hover {
  box-shadow: none;
}

.newsFilterSelect .Dropdown-menu,
.adminTableFilterSelectSort .Dropdown-menu {
  width: 250px;
  white-space: pre;
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 999;
  scrollbar-width: thin;
  max-height: 250px;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 8px;
}

.newsFilterSelect .Dropdown-menu {
  left: 0;
  transform: translateX(-20.5px);
}

.newsFilterSelect .Dropdown-menu {
  width: fit-content;
}

.adminTableFilterSelect .Dropdown-menu {
  width: 75px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  left: -30px;
  z-index: 999;
  scrollbar-width: thin;
  max-height: 250px;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 8px;
}

.adminTableFiltercheckBox .Dropdown-menu {
  width: 160px;
}

.newsFilterSelect .Dropdown-menu::-webkit-scrollbar,
.adminTableFilterSelectSort .Dropdown-menu::-webkit-scrollbar,
.adminTableFilterSelect .Dropdown-menu::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #F5F5F5;
}

.newsFilterSelect .Dropdown-menu::-webkit-scrollbar,
.adminTableFilterSelectSort .Dropdown-menu::-webkit-scrollbar-thumb,
.adminTableFilterSelect .Dropdown-menu::-webkit-scrollbar-thumb {
  height: 8px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #D9DFE4;
}

.Dropdown-option {
  word-break: normal !important;
}

.newsFilterSelect .Dropdown-menu .Dropdown-option,
.adminTableFilterSelectSort .Dropdown-menu .Dropdown-option,
.adminTableFilterSelect .Dropdown-menu .Dropdown-option {
  color: #0F1324;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 20px !important;
  margin: 0 !important;
}

.newsFilterSelect .Dropdown-menu .Dropdown-option:hover,
.adminTableFilterSelectSort .Dropdown-menu .Dropdown-option:hover,
.adminTableFilterSelect .Dropdown-menu .Dropdown-option:hover {
  color: #2c4135;
  background: transparent;
}

.newsFilterSelect .Dropdown-menu .Dropdown-option.is-selected,
.adminTableFilterSelectSort .Dropdown-menu .Dropdown-option.is-selected,
.adminTableFilterSelect .Dropdown-menu .Dropdown-option.is-selected {
  background: rgb(188, 164, 127, 0.25);//#F6F6F9;
  border-radius: 8px;
  color: #2c4135;
}

.newsFilterSelect .Dropdown-arrow-wrapper,
.adminTableFilterSelectSort .Dropdown-arrow-wrapper,
.adminTableFilterSelect .Dropdown-arrow-wrapper {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 6px;
  right: 0px;
  pointer-events: none
}

.newsFilterSelect .Dropdown-arrow-wrapper:before,
.newsFilterSelect .Dropdown-arrow-wrapper:after,
.adminTableFilterSelect .Dropdown-arrow-wrapper:before,
.adminTableFilterSelectSort .Dropdown-arrow-wrapper:before,
.adminTableFilterSelect .Dropdown-arrow-wrapper:after,
.adminTableFilterSelectSort .Dropdown-arrow-wrapper:after {
  content: '';
  display: block;
  height: 1px;
  width: 5px;
  position: absolute;
  top: 7px;
  background-color: #0F1324;
}

.newsFilterSelect .Dropdown-arrow-wrapper:before,
.adminTableFilterSelectSort .Dropdown-arrow-wrapper:before,
.adminTableFilterSelect .Dropdown-arrow-wrapper:before {
  transform-origin: right;
  transform: rotateZ(40deg);
  left: 0;
}

.adminTableFilterSelectSort .Dropdown-arrow-wrapper:after,
.newsFilterSelect .Dropdown-arrow-wrapper:after,
.adminTableFilterSelect .Dropdown-arrow-wrapper:after {
  transform-origin: left;
  transform: rotateZ(-40deg);
  right: 0;
}

.adminTableFilterSelect-dropdown-arrow {
  display: none;
}

.adminTableFilterMultiLang .adminTableFilterSelect-dropdown-control .Dropdown-arrow-wrapper {
  top: 2px;
}

.adminTableFilterMultiLang .adminTableFilterSelect-dropdown-control .Dropdown-placeholder {
  font-size: 14px;
  line-height: 14px;
  padding-left: 8px;
  color: #2c4135;
}

.adminTableFilterMultiLang .adminTableFilterSelect-dropdown-control {
  background-color: transparent;
}

.newsFilterSelect.adminTableFilterMultiLang .Dropdown-menu,
.adminTableFilterSelect.adminTableFilterMultiLang .Dropdown-menu {
  width: unset !important;
}

.adminTableFilterSelectSort .Dropdown-menu{ left: 0}

.newsFilterSelect.adminTableFilterMultiLang .Dropdown-menu .Dropdown-option,
.adminTableFilterSelect.adminTableFilterMultiLang .Dropdown-menu .Dropdown-option {
  padding: 6px 0 !important;
  text-align: left;
}

.multyErr .dropdownAdminError {
  border-color: #FF453A;
  box-shadow: 0 0 0 1px #FF453A;
  background-color: rgba(255, 69, 58, 0.03);
}

.multyErr .dropdownTitleWrapper > p {
  color: #FF453A !important;
}


.DropDownGray .Dropdown-root .dropdown-control {
  background-color: rgba(143, 143, 171, .1);
  transition: all ease .25s;
  color: #0F1324;
}

.DropDownGray .Dropdown-root .dropdown-control:hover {
  background-color: rgba(15, 19, 36, 0.08);
  transition: all ease .25s
}

.DropDownGray .Dropdown-root .dropdown-placeholder {
  color: #0F1324;
}

.DropDownGray .Dropdown-root .Dropdown-arrow {
  border-color: #0F1324 !important;
  display: none;
}

.DropDownGray .Dropdown-menu {
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.DropDownGray .Dropdown-root .Dropdown-option {
  transition: all ease .25s;
}

.DropDownGray .Dropdown-root .Dropdown-option.is-selected, .DropDownGray .Dropdown-root .Dropdown-option:hover {
  color: #0F1324;
  transition: all ease .25s;
}

.DropDownGray .Dropdown-root .dropdown-control {
  padding: 12px;
  height: 44px;
}


@media(min-width: 992px) {
  .DropDownGray .Dropdown-root .dropdown-control {
    padding: 12px 39px 12px 16px;
    height: 48px;
  }
  .DropDownGray .Dropdown-root .Dropdown-arrow {
    display: block;
  }
}

.w-100-input input {
  width: 100% !important;
}