
.modal, .modalTop, .modalBottom, .modalWhiteBack, .modalTransparentBack, .modalCenter{
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 19, 36, 0.4);
  z-index: 9999;
}

.modal_inline {
  display: inline-block;
  padding-top: 40px;
}


.positionImg{
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(15, 19, 36, 0.6);
  z-index: 999;
}

.imgPortal{
  display: flex !important;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 100vh !important;
}

.mobileSliderImg{
  display: flex !important;
}

.modalBottomDown {
  top: auto;
  background-color: transparent;
}
.modalWhiteBack {
  background-color: white;
}
.modalTransparentBack{
  background-color: transparent;
}

.modalTop{
  align-items: flex-end;
}
.modalCenter{
  align-items: center;
}
.modal:before,
.modal:after{
  content: '';
  display: block;
}

.modal:before{
  flex-grow: 1;
}

.modal:after{
  flex-grow: 1;
}

.modalTop:after,
.modalCenter:after,
.modalBottom:before{
  content: '';
  display: block;
  flex-grow: 1;
}

#portal{
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: none;
}

#portal>div{
  width: 100%;
  height: 100%;
}
.modal_blur {
  backdrop-filter: blur(6px);
}