@use '/project/uikit/uikit' as *;

.notificationsQuick {
    background-color: var(--whiteColor);
    padding: 16px 0 0;
    width: 100%;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid var(--darkMoreTransparentColor);
    border-bottom: none;
    box-shadow: 0 14px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    position: relative;
    margin-bottom: 8px;

    @keyframes newNotificationStart {
        from {
            right: -100%;
        }
        to {
            right: 0;
        }
    }

    @keyframes newNotificationEnd {
        from {
            right: 0;
        }
        to {
            right: calc(-100% - 24px);
            //right: 0;
        }
    }

    animation: newNotificationStart, newNotificationEnd;
    animation-fill-mode: forwards, forwards;
    animation-duration: 0.2s, 0.4s;
    animation-timing-function: linear, linear;
    animation-delay: 0s, 14.6s;

    &Title {
        @include text;
        @include medium;
        margin-bottom: 8px;
        padding-right: 30px;
        overflow-wrap: anywhere;
        word-break: break-word;
    }

    &SubTitle {
        @include small;
        color: var(--blueTotalGray);
        margin-bottom: 1rem;
        overflow-wrap: anywhere;
        word-break: break-word;
    }

    &Loader {
        width: 100%;
        background: var(--greyMainColor);
        height: 4px;
        border-radius: 0 0 8px 8px;
        flex-basis: 100%;
        overflow: hidden;

        &Progress {
            @keyframes loading {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
            animation: loading;
            animation-fill-mode: forwards;
            animation-duration: 15s;
            animation-timing-function: linear;
            height: 100%;
            background-color: var(--greenColor);
        }
    }

    &ModalClose {
        position: absolute;
        top: 11px;
        right: 15px;
        cursor: pointer;
    }
}

.notificationsIconWrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--greenTransparentColor);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-left: 16px;
}
