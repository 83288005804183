@import 'project/uikit/_media.scss';

@mixin container {
  width: 100%;
  margin: 0 auto;

  @include max-md {
    padding-left: 12px;
    padding-right: 12px;
  }
  @include min-md {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (min-width: 1200px) and (max-width: 1299px) {
    max-width: 1504px;
  }
  @media (min-width: 1300px) {
    max-width: 1716px;
  }
}

@mixin mt0 {
  margin-top: 0;
}

@mixin mt1 {
  margin-top: 0.1875rem;
}

@mixin mt2 {
  margin-top: 0.5rem;
}

@mixin mt3 {
  margin-top: 1rem;
}

@mixin mt4 {
  margin-top: 1.5rem;
}

@mixin mt5 {
  margin-top: 2rem;
}

@mixin ml0 {
  margin-left: 0;
}

@mixin ml1 {
  margin-left: 0.1875rem;
}

@mixin ml2 {
  margin-left: 0.5rem;
}

@mixin ml3 {
  margin-left: 1rem;
}

@mixin ml4 {
  margin-left: 1.5rem;
}

@mixin ml5 {
  margin-left: 2rem;
}

@mixin mr0 {
  margin-right: 0;
}

@mixin mr1 {
  margin-right: 0.1875rem;
}

@mixin mr2 {
  margin-right: 0.5rem;
}

@mixin mr3 {
  margin-right: 1rem;
}

@mixin mr4 {
  margin-right: 1.5rem;
}

@mixin mr5 {
  margin-right: 2rem;
}

@mixin ml1_minus {
  margin-left: -0.1875rem;
}

@mixin ml2_minus {
  margin-left: -0.5rem;
}

@mixin ml3_minus {
  margin-left: -1rem;
}

@mixin ml4_minus {
  margin-left: -1.5rem;
}

@mixin ml5_minus {
  margin-left: -2rem;
}

@mixin mr1_minus {
  margin-right: -0.1875rem;
}

@mixin mr2_minus {
  margin-right: -0.5rem;
}

@mixin mr3_minus {
  margin-right: -1rem;
}

@mixin mr4_minus {
  margin-right: -1.5rem;
}

@mixin mr5_minus {
  margin-right: -2rem;
}

@mixin mb0 {
  margin-bottom: 0;
}

@mixin mb1 {
  margin-bottom: 0.1875rem;
}

@mixin mb2 {
  margin-bottom: 0.5rem;
}

@mixin mb3 {
  margin-bottom: 1rem;
}

@mixin mb4 {
  margin-bottom: 1.5rem;
}

@mixin mb5 {
  margin-bottom: 2rem;
}

@mixin pt0 {
  padding-top: 0;
}

@mixin pt1 {
  padding-top: 0.1875rem;
}

@mixin pt2 {
  padding-top: 0.5rem;
}

@mixin pt3 {
  padding-top: 1rem;
}

@mixin pt4 {
  padding-top: 1.5rem;
}

@mixin pt5 {
  padding-top: 2rem;
}

@mixin pl0 {
  padding-left: 0;
}

@mixin pl1 {
  padding-left: 0.1875rem;
}

@mixin pl2 {
  padding-left: 0.5rem;
}

@mixin pl3 {
  padding-left: 1rem;
}

@mixin pl4 {
  padding-left: 1.5rem;
}

@mixin pl5 {
  padding-left: 2rem;
}

@mixin pr0 {
  padding-right: 0;
}

@mixin pr1 {
  padding-right: 0.1875rem;
}

@mixin pr2 {
  padding-right: 0.5rem;
}

@mixin pr3 {
  padding-right: 1rem;
}

@mixin pr4 {
  padding-right: 1.5rem;
}

@mixin pr5 {
  padding-right: 2rem;
}

@mixin pb0 {
  padding-bottom: 0;
}

@mixin pb1 {
  padding-bottom: 0.1875rem;
}

@mixin pb2 {
  padding-bottom: 0.5rem;
}

@mixin pb3 {
  padding-bottom: 1rem;
}

@mixin pb4 {
  padding-bottom: 1.5rem;
}

@mixin pb5 {
  padding-bottom: 2rem;
}
@mixin capitalize {
  text-transform: capitalize;
}
@mixin lowercase {
  text-transform: lowercase;
}

//buttons

@mixin button {
  text-align: center;
  cursor: pointer;
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.3334;
  font-weight: 500;
  padding: 1.25rem;
}

@mixin buttonSm {
  text-align: center;
  cursor: pointer;
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.75rem;
  line-height: 1.5;
}

@mixin width100 {
  width: 100%;
}

@mixin positionAbsolute {
  position: absolute;
}

@mixin positionRelative {
  position: relative;
}

@mixin pointer {
  cursor: pointer;
}

@mixin none {
  display: none;
}

@mixin block {
  display: block;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexColumnReverse {
  display: flex;
  flex-direction: column-reverse;
}

@mixin flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

@mixin flexInline {
  display: inline-flex;
}

@mixin flexGrow1 {
  flex-grow: 1;
}

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin alignItemsCenter {
  align-items: center;
}

@mixin alignItemsStart {
  align-items: flex-start;
}

@mixin alignItemsEnd {
  align-items: flex-end;
}

@mixin alignItemsUnset {
  align-items: unset;
}

@mixin justifyContentStart {
  justify-content: flex-start;
}

@mixin justifyContentCenter {
  justify-content: center;
}

@mixin justifyContentEnd {
  justify-content: flex-end;
}

@mixin justifyContentBetween {
  justify-content: space-between;
}

@mixin flexWrap {
  flex-wrap: wrap;
}

@mixin flexNoWrap {
  flex-wrap: nowrap;
}

@mixin noWrap {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin lineClamp($value) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;
  line-clamp: $value;
  box-orient: vertical;
}

@mixin hr {
  opacity: 0.5;
  border-bottom-width: 1px;
  border-style: solid;
  display: flex;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 40px;
}

@mixin textCenter {
  text-align: center;
}

@mixin medium {
  font-weight: 500;
}

@mixin regular {
  font-weight: 400;
}

@mixin input {
  font-size: 1rem;
}

@mixin h1 {
  // 82px
  font-size: 5.125rem;
  line-height: 1.17;
  font-weight: 400;
}

@mixin h2 {
  // 60px
  font-size: 3.75rem;
  line-height: 1.16;
  font-weight: 400;
}

@mixin h3 {
  // 42px
  font-size: 2.625rem;
  line-height: 1.19;
  font-weight: 500;
}

@mixin h4 {
  // 36px
  font-size: 2.25rem;
  line-height: 1.2;
  font-weight: 500;
}

@mixin h5 {
  // 28px
  font-size: 1.75rem;
  line-height: 1.28;
}

@mixin h6 {
  // 24px
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 400;
}

@mixin title {
  // 20px
  font-size: 1.25rem;
  line-height: 1.3;
}

@mixin subtitle {
  // 18px
  font-size: 1.125rem;
  line-height: 1.44;
}

@mixin text {
  // 16px
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

@mixin small {
  // 14px
  font-size: 0.875rem;
  line-height: 1.4;
}

@mixin tiny {
  // 12px
  font-size: 0.75rem;
  line-height: 1.33;
}
